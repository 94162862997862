$publicPath:'';

.client-page {
  padding-bottom: 100px;
}
.page {
  display: flex;
  justify-content: center;
  padding-top: 46px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      width: 1200px;
      min-height: 630px;
      display: flex;
      .left {
        max-width: 728px;
        flex: 1;
        margin-right: 66px;
        margin-bottom: 10px;
        height: 100%;
        .title {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 30px;
          color: #000000;
          margin-bottom: 10px;
        }
        .createInfo {
          display: flex;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
          margin-bottom: 16px;
          .user {
            &::before {
              position: relative;
              top: 2px;
              content: "";
              margin-right: 5px;
              display: inline-block;
              width: 14px;
              height: 14px;
              background: url("~assets/img/icon/user-icon.png") no-repeat
                center/cover;
            }
          }
          .clock {
            &::before {
              position: relative;
              top: 2px;
              content: "";
              margin-left: 10px;
              margin-right: 2px;
              display: inline-block;
              width: 14px;
              height: 14px;
              background: url("~assets/img/icon/clock-icon.png") no-repeat
                center/cover;
            }
          }
        }
        .article {
          height: 100%;
        }
      }
      .right {
        width: 332px;
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #000000;
          margin-bottom: 24px;
        }
        ul {
          li {
            &::before {
              content: "";
              margin-right: 8px;
              display: inline-block;
              position: relative;
              width: 4px;
              height: 4px;
              top: 50%;
              transform: translateY(-100%);
              background: #09a666;
              opacity: 0.2;
            }
            overflow: hidden; //溢出隐藏
            white-space: nowrap; //禁止换行
            text-overflow: ellipsis; //...
            margin-bottom: 16px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
            transition: all 0.5s;
            span {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
